.ChatPage {
  // margin: 30px;
  // height: 960px;

  background: #ffffff;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  .top-section {
    // border: solid;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // margin: 10px 0;
    // padding: 0 30px;
    .profile-container,
    .icon-container {
      svg {
        font-size: 30px;
      }
      b {
        font-family: "Syne";
        font-style: normal;
        font-weight: 300;
        line-height: 10px;
        font-size: 14px;

        color: #0000fa;
      }
      // border: solid 1px red;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
  .bottom-section {
    // border: solid;
    position: fixed;
    bottom: 0px;
    margin-top: 500px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    // margin: 30px 0;
    padding: 30px 0;
    gap: 20px;
    background-color: #ffffff;
    input {
      // width: 852px;
      height: 39px;
      border: 1px solid #000aff;
      border-radius: 24px;
    }
    .icon-container {
      // border: solid 1px red;
      display: flex;
      align-items: center;
      gap: 15px;
      svg {
        font-size: 35px;
        color: #0000ff;
      }
    }
    .send-button {
      width: 44px;
      height: 44px;

      background: #0000ff;
      box-shadow: 0px 4px 4px rgba(56, 121, 254, 0.2);
      border-radius: 50%;
      color: #fff;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      svg {
        font-size: 20px;
        color: #ffffff;
      }
    }
  }

  .chat-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 30px;
    .ChatCard {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 10px;
      // border: solid;
      max-width: 733px;
      // min-height: 61px;
      margin: 10px 20px;

      // text
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 10px;
      p {
        font-size: 10px;
        line-height: 5px;
        margin-top: 5px;
        margin-left: auto;
        margin-bottom: 0.5rem !important;
      }
    }
    .you {
      background: #eeeeee;
      border-radius: 10px 10px 10px 2px;

      color: #111111;
      margin-right: auto;
      p {
        color: rgba(17, 17, 17, 0.8);
      }
    }
    .me {
      background: #000aff;
      border-radius: 10px 10px 2px 10px;

      color: #ffffff;
      margin-left: auto;
      text-align: end;
      p {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
.progressbar {
  width: 75%;
  height: 0;
  margin: 10px auto;
}
.FileCard {
  // border: solid;
  position: absolute;
  bottom: 80px;
  z-index: 900;
  width: 100%;
  height: 350px;
  background-color: #111111;
  color: #ddd;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
    cursor: pointer;
  }
  .preview-container {
    // border: solid;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    .icon {
      font-size: 100px;
    }
    img {
      // border: solid;
      width: auto;
      max-width: 400px;
      height: 80%;
      max-height: 200px;
    }
  }
  form {
    // border: solid;
    padding: 10px 20px;
    gap: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    height: 55px;
    align-items: center;
    input {
      // width: 852px;
      height: 39px;
      border: 1px solid #000aff;
      border-radius: 24px;
    }
    .icon-container {
      // border: solid 1px red;
      display: flex;
      align-items: center;
      gap: 15px;
      svg {
        font-size: 35px;
        color: #0000ff;
      }
    }
    .send-button {
      width: 44px;
      height: 44px;

      background: #0000ff;
      box-shadow: 0px 4px 4px rgba(56, 121, 254, 0.2);
      border-radius: 50%;
      color: #fff;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      svg {
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
}
