.CreateProjectPage {
	// border: solid;
	.page-name-container {
		margin-top: 20px;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.page-name {
			font-family: "Quicksand";
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;

			color: #000000;

			p {
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
			}
		}
		button {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 12px 14px;
			gap: 10px;

			width: 128px;
			height: 47px;

			background: #0000ff;
			border-radius: 5px;
			// text
			font-family: "Quicksand";
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			/* identical to box height */

			color: #ffffff;

			/* Inside auto layout */

			flex: none;
			order: 0;
			flex-grow: 0;
		}
	}

	.gallery-container {
		.image-container {
			width: 76px;
			height: 68px;

			border-radius: 5px;
			margin: 10px 0;
		}
	}
}
// .dashboard-gride {
//   .col-md-9 {
//     // border: solid;
//     width: 70%;
//     // padding-left: 20px;
//     /*

//     over-view-container
//     */

//     // .over-view-container {
//     //   margin-top: 23px;
//     //   // border: solid red;
//     //   height: 362px;
//     //   padding: 17px;

//     //   // background: #393838;
//     //   border-radius: 10px;

//     //   h1 {
//     //     font-family: "Syne";
//     //     font-style: normal;
//     //     font-weight: 600;
//     //     font-size: 24px;
//     //     line-height: 29px;
//     //     /* identical to box height */
//     //     margin-left: 6px;
//     //     display: flex;
//     //     align-items: flex-end;
//     //     text-align: center;

//     //     /* White/Silver */

//     //     color: #c0c0c0;
//     //   }

//     //   .card {
//     //     // background: #111111;
//     //     border-radius: 10px;
//     //     margin: 5px 0;
//     //     padding: 16px;
//     //     span {
//     //       font-family: "Quicksand";
//     //       font-style: normal;
//     //       font-weight: 400;
//     //       font-size: 13px;
//     //       line-height: 16px;
//     //       /* identical to box height */

//     //       display: flex;
//     //       align-items: flex-end;

//     //       /* Grey/Grey */

//     //       color: #808080;
//     //     }

//     //     h2 {
//     //       font-family: "Syne";
//     //       font-style: normal;
//     //       font-weight: 600;
//     //       font-size: 30px;
//     //       line-height: 36px;
//     //       display: flex;
//     //       align-items: flex-end;

//     //       /* White/White */

//     //       color: #ffffff;
//     //     }
//     //   }

//     //   .sub-gride {
//     //     margin-left: 6px;
//     //     .col-12 {
//     //       height: 88px;
//     //     }
//     //     .col-md-6 {
//     //       height: 103px;
//     //       // margin:0 6px ;
//     //       // max-width: 160px !important;
//     //     }
//     //   }
//     //   .compass-container {
//     //     padding-bottom: 10px;
//     //     button {
//     //       // width: 93px;
//     //       height: 24px;

//     //       background: #393838;
//     //       border-radius: 5px;

//     //       //
//     //       font-family: "Syne";
//     //       font-style: normal;
//     //       font-weight: 400;
//     //       font-size: 16px;
//     //       line-height: 19px;
//     //       padding: 6px;
//     //       // display: block;
//     //       align-items: center;

//     //       // display: flex;
//     //       // align-items: center;

//     //       color: #070707;
//     //     }
//     //     button.active {
//     //       background: #ffffff;
//     //       color: rgba(17, 17, 17, 0.5);
//     //     }
//     //   }
//     //   .recode-container {
//     //     // border: solid;
//     //     margin: 0 auto;
//     //     // margin-top: auto;
//     //     width: 225px;
//     //     // height: 40px;

//     //     background: #393838;
//     //     border-radius: 10px;
//     //     display: flex;
//     //     justify-content: space-between;
//     //     padding: 7px 12px;
//     //     h2 {
//     //       font-family: "Syne";
//     //       font-style: normal;
//     //       font-weight: 600;
//     //       font-size: 30px;
//     //       line-height: 36px;
//     //       display: flex;
//     //       align-items: flex-end;

//     //       /* White/White */

//     //       color: #ffffff;
//     //     }

//     //     span {
//     //       font-family: "Quicksand";
//     //       font-style: normal;
//     //       font-weight: 500;
//     //       font-size: 8px;
//     //       line-height: 8px;
//     //       /* identical to box height */

//     //       display: flex;
//     //       align-items: flex-end;
//     //       letter-spacing: 0.025em;

//     //       /* White/White */

//     //       color: #ffffff;
//     //     }
//     //     .vertical-line {
//     //       // min-width: 4px !important; /* Set the width of the line */
//     //       // height: 40px; /* Make the line as tall as the parent element */
//     //       // background-color:  black; /* Add a left border to the element */
//     //       // position: absolute;
//     //       width: 25px;
//     //       height: 0px;
//     //       margin: auto 0;
//     //       // left: 893px;
//     //       // top: 682px;

//     //       opacity: 0.2;
//     //       /* White/Silver */

//     //       border: 1.5px solid #c0c0c0;
//     //       transform: rotate(90deg);
//     //     }
//     //   }
//     // }

//     // financial-report-container

//     .financial-report-container {
//       // width: 778px;
//       margin-top: 28px;
//       height: 400px;

//       /* White/White */

//       background: #ffffff;
//       border-radius: 10px;
//       .top-div {
//         padding: 25px;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         h1 {
//           font-family: "Syne";
//           font-style: normal;
//           font-weight: 600;
//           font-size: 30px;
//           line-height: 36px;
//           /* identical to box height */

//           display: flex;
//           align-items: flex-end;

//           color: #485465;
//         }
//       }

//       canvas {
//         // border: solid 1px #000;
//         max-height: 280px !important;
//       }
//     }
//   }
//   .col-md-3 {
//     // border: solid red;
//     width: 30%;
//     .NotificationCol {
//       // width: 329px;
//       padding: 12px 23px;
//       // min-height: 844px;

//       // background: #ffffff;
//       border-radius: 14.0097px;
//       .top-container {
//         display: flex;
//         justify-content: space-between;

//         align-items: center;
//         h2 {
//           font-family: "Quicksand";
//           font-style: normal;
//           font-weight: 700;
//           font-size: 18px;
//           line-height: 40px;
//           /* or 222% */

//           /* DMV/Black */

//           color: #23262f;
//         }
//         a {
//           font-family: "Quicksand";
//           font-style: normal;
//           font-weight: 600;
//           font-size: 14.0097px;
//           line-height: 24px;
//           /* or 171% */

//           /* Light Blue */

//           color: #145c9e;
//         }
//       }

//       .notification-container {
//         // border: solid;
//         // .card {
//         //   border: none !important;
//         //   border-width: 1px;
//         //   display: flex;
//         //   flex-direction: row;
//         //   gap: 10px;
//         //   align-items: center;
//         //   // justify-content: space-between;
//         //   // width: 278.19px;
//         //   min-height: 56.04px;
//         //   padding: 11px;
//         //   margin: 11px 0;

//         //   // background: #eee6e2;
//         //   border-radius: 10.0069px;
//         //   .text-container {
//         //     h2 {
//         //       font-family: "Quicksand";
//         //       font-style: normal;
//         //       font-weight: 700;
//         //       font-size: 13px;
//         //       line-height: 16px;

//         //       /* DMV/Black */

//         //       color: #23262f;
//         //     }
//         //     span {
//         //       font-family: "Quicksand";
//         //       font-style: normal;
//         //       font-weight: 400;
//         //       font-size: 10.0069px;
//         //       line-height: 13px;
//         //       /* identical to box height */

//         //       color: #708099;
//         //     }
//         //   }
//         // }
//         .no-notification {
//           // width: 278.19px;
//           width: 100%;
//           height: 56.11px;
//           display: flex;
//           // justify-content: center;
//           padding: 0 20px;
//           align-items: center;
//           background: #eee6e2;
//           border-radius: 10.0069px;

//           // text
//           font-family: "Quicksand";
//           font-style: normal;
//           font-weight: 700;
//           font-size: 13px;
//           line-height: 16px;
//           /* identical to box height */

//           /* DMV/Black */

//           color: #23262f;
//         }
//       }
//     }
//   }
// }
.SearchSkillsComponent {
	padding: 22px;
	max-width: 362px;
	min-height: 528px;

	display: flex;
	flex-direction: column;
	gap: 20px;

	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	.ExpertiseCard-container {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		// padding: 10px;
	}
}

.ExpertiseCard {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 9px;
	gap: 9px;

	// width: 120px;
	height: 30px;

	background: rgba(0, 0, 255, 0.2);
	border-radius: 24px;

	// text

	font-family: "Quicksand";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height */

	text-align: center;

	color: rgba(17, 17, 17, 0.7);

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}
