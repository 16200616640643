
.ApplicationPage{
  padding: 0 39px;
}
.ApplicationCardComponent{
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
width: 100%;
height: 112px;

background: #FFFFFF;
border-radius: 10px;
align-items: center; 
margin: 20px 0;
div{
  width: 30%;
} 
h1{
    font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
/* identical to box height */


/* Grey/Dark Grey */

color: #111111;

  }
  p{
    
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 145%;
/* or 23px */


/* Base/80 */

color: #707070;
  }
  .badge-container{
    display: flex;
    justify-content: center;
  .badge{
    /* Frame 1000000939 */


/* Auto layout */

display: flex;
align-items: center;
justify-content: center;

width: 326px;
height: 37px;

background: #EEF2FA;
border-radius: 50px;


// text
font-family: 'Quicksand';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px;
/* identical to box height */


color: #5175BA;


  }
  .badge.Accepted{
   color: #5175BA;
  background: #EEF2FA;
  }
  .badge.Pending{
    color: #DDA479;
    background: #FFF4EC;
  }
  .badge.Rejected{
  color: #FA7182;
   background: #FCE3E3;
  }
}
.icon-container{
  width: 20%;

  display: flex;
  justify-content: flex-end;
}
}