@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Syne', sans-serif;
  // overflow: hidden;
  .custom-scrollbar::-webkit-scrollbar {
    width: 3px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #1b0f45;
    border-radius: 6px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #e0e0e0;
  }

  .custom-scrollbar {
    scrollbar-width: 10px;
    scrollbar-color: #221c33 #e0e0e0;
  }
}

:root {
  --primary-Blue: #0000ff;
  --text-muted: #c0c0c0;
}

.Toastify {
  // border: solid red;
  position: absolute;
  right: 0;
  .Toastify__toast-container {
    // border: solid green;
    .Toastify__toast-body {
      padding: 10px 20px 10px 5px;
      background-color: #fff;
      min-width: 350px;
      border: solid royalblue;
      display: flex;
      // justify-content:center;
      gap: 10px;
      align-items: center;
      .Toastify__toast-icon {
        // border: solid palegoldenrod;
        width: 40px;
      }
    }
  }
}

// Tailwindcss styles

.add-project-input {
  @apply bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none;
}
.signup-input {
  @apply bg-white w-full rounded-lg p-4 outline-none;
}

.btn-primary {
  @apply bg-[#273650] text-white w-full p-2 mt-3 rounded-lg text-sm hover:bg-opacity-80;
}
.withdraw-input {
  @apply bg-[#f0f0f0] outline-none w-full py-2 px-4 rounded-lg  text-sm;
}

.card-sm {
  @apply outline-none border-none w-full p-2 rounded-lg;
}

h2 {
  font-size: 2rem;
}
h4 {
  font-size: 2rem;
}
.style-form-input {
	@apply bg-gray-100 w-full rounded-lg px-3 py-2 border-none focus:outline-none outline-none;
}