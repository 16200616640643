.SearchProjectPage {
  .icon-btn {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0000ff;
  }
  .search-info {
    margin-top: 20px;
    display: flex;
    justify-items: center;
    padding: 0 39px;

    p {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: 14.0097px;
      line-height: 18px;
      /* identical to box height */

      /* Grey/Dark Grey */

      color: #111111;
    }
    sup {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      /* Grey/Grey */

      color: #808080;
    }

    button.dropdown {
      /* Group 1000000889 */

      /* Auto layout */

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 10px;
      // gap: 12px;
      background: transparent !important;

      // position: absolute;
      // left: 80.42%;
      // right: 9.24%;
      // top: 21.82%;
      // bottom: 75.08%;

      border: 1.5px solid #b3b3ff;
      border-radius: 50px;
    }
  }
}
.project-list-container {
  padding: 10px 32px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.searchComponent {
  // border: solid;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  max-width: 100%;
  margin: 20px 39px;
  height: 68px;
  left: 304px;
  top: 118px;

  background: #ffffff;
  border-radius: 10px;
  button.search-dropdown {
    background: transparent !important;
    // text
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 14.0097px;
    line-height: 18px;
    /* identical to box height */

    /* Grey/Dark Grey */

    color: #111111;

    svg {
      color: #0000ff;
      font-size: 20px;
    }
    &:focus {
      background: transparent !important;
    }
  }

  button.find-button,
  button.filter-button {
    width: 140px;
    height: 45px;

    border-radius: 50px;

    // text/
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
  }
  button.filter-button {
    background: rgba(0, 0, 255, 0.3) !important;
  }
  button.filter-button.open {
    /* Grey/Dark Grey Faded */

    border: 2px solid rgba(17, 17, 17, 0.5);
    color: rgba(17, 17, 17, 0.5) !important;

    background-color: transparent !important;
  }
  color: #0000ff;
  button.find-button {
    background: #0000ff !important;
    color: #ffffff;
  }
  input {
    max-width: 500px;
    height: 100%;
    border: none;
    &:focus-visible {
      outline: none;
      border: none;
      border-color: transparent;
      box-shadow: none;
    }
  }
}

.SuggestionsScrollComponent {
  // border: solid;
  display: flex;
  justify-items: center;
  align-items: center;
  padding-left: 39px;
  height: 60px;
  // text
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;

  .Suggestion-container {
    overflow-x: scroll;
    display: flex;
    padding: 10px;
    gap: 10px;
    width: 100%;
    margin: 0 39px 0 10px;
    button {
      // border: solid;

      /* Auto layout */

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 12px;
      gap: 12px;

      // width: 107px;
      height: 40px;

      /* White/Silver */

      background: #c0c0c0;
      border-radius: 50px;

      // text
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      white-space: nowrap;
      color: rgba(17, 17, 17, 0.4);
    }
    button.active {
      /* Cool Gradient */

      background: linear-gradient(
        264.78deg,
        #ff693f 3.98%,
        #eb00b9 53.49%,
        #5f38ff 100%
      );
      color: rgba(255, 255, 255, 0.7);
    }
    &::-webkit-scrollbar {
      height: 10px;
      width: 12px; /* Width of the entire scrollbar */
      background-color: transparent; /* Background color of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      border-radius: 10px; /* Rounded corners of the scrollbar thumb */
      background-color: var(
        --primary-Blue
      ); /* Background color of the scrollbar thumb */
    }
  }

  .filter-form-container {
    // border: solid;
    width: 100%;
    display: flex;
    gap: 10px;
    padding-right: 32px;
    button.chakra-button {
      // width: 100%;
      // background: #D9D9D9;
      // border: solid red;
      background: #d9d9d9 !important;
    }
    label {
      // border: solid;
      width: 200px;
      span.label-name {
        // text
        font-family: "Quicksand";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        /* Grey/Grey */

        color: #808080;
      }
      .chakra-input__group {
        background: #d9d9d9;
        border-radius: 5px;

        button {
          background-color: transparent !important;
          height: 100%;
          // text
          font-family: "Quicksand";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          /* identical to box height */

          /* Blue Plimary Colors/Blue */

          color: #0000ff;
        }
      }
    }
  }
}

.ProjectCardComponent {
  // border: solid;
  width: 330px;
  height: 253px;
  cursor: pointer;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .top,
  .down {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .top {
    h3 {
      font-family: "Syne";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      /* Grey/Dark Grey */

      color: #111111;
    }
    p {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-top: 8px;
      /* identical to box height */

      /* Blue Plimary Colors/Blue */

      color: #0000ff;
    }
  }
  p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin: auto 0;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    /* or 129% */

    /* Grey/Dark Grey Faded */

    color: rgba(17, 17, 17, 0.5);
  }
  .down {
    margin-top: auto;
    button {
      height: 33px;
      min-width: 107px;
      width: auto;
      padding: 0 10px;
      /* Cool Gradient */

      background: linear-gradient(
        264.78deg,
        #ff693f 3.98%,
        #eb00b9 53.49%,
        #5f38ff 100%
      );
      border-radius: 20px;

      // text

      font-family: "Quicksand";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      /* identical to box height, or 112% */

      letter-spacing: 0.03em;

      color: #ffffff;
    }
    // text

    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */

    letter-spacing: 0.03em;

    /* Grey/Dark Grey */

    color: #111111;
  }
}
