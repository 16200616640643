.CallPage {
  // border: solid red;
  background-color: black;
  color: #fff;
  z-index: 9000;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  // align-self: center;
  // position: absolute;
  display: flex;
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    background-color: #00000064;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .dialing-container {
    // border: solid;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .chakra-avatar {
      width: 150px;
      height: 150px;
    }
    b {
      font-size: 20px;
    }
    p {
      font-size: 30px;
      font-weight: bold;
    }
    button {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      font-size: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button.end-call {
      background-color: red;
      color: #fff;
    }
  }
  .video-container {
    display: flex;
    align-items: center;
    // flex-direction: column;
    // position: relative;
    width: 100%;
    height: 100%;
    min-width: 500px !important;
    // border: solid;
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // top: 0;
    p.name {
      position: absolute;
      background-color: #00000064;
      width: 50%;
      padding: 10px 30px;
      font-size: 20px;
    }
    .video-frame {
      // border: solid 1px red;
      width: 100%;
      height: 100%;
      max-height: 500px;
      display: flex;
      justify-content: center;
      video {
        height: 100%;
      }
    }

    // video{

    //   border: solid red;
    //       width: 100%;
    //       height: 100%;
    //       margin: auto;
    //     }
    //     video::-webkit-media-controls {
    //       display: none;
    //     }
    .CallButton {
      position: absolute;
      // border: red solid;
      bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      button {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button.mic,
      button.voice {
        background-color: rgb(2, 169, 246);
        color: #fff;
      }
      button.end-call {
        background-color: red;
        color: #fff;
      }
      button.pick-call {
        background-color: green;
        color: #fff;
      }
    }
  }
}

.VideoCallCardHover {
  // border: solid;
  position: absolute;
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 30px;
  right: auto;
  left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 20px;
    // border: solid 1px #000;
    width: 400px;
    border-radius: 30px;
    background-color: #111111;
    color: #fff;
    box-shadow: 1px 1px 19px 6px rgba(0, 0, 0, 0.5);
    .buttons {
      display: flex;
      gap: 10px;
      button {
        // border: solid;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
      }
      button.end {
        background-color: red;
        color: #fff;
      }
      button.pick {
        background-color: green;
        color: #fff;
      }
    }
  }
}
