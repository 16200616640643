.ProjectPage {
  padding: 0 32px;
  margin-top: 35px;
  .profile-card {
    position: relative;
    display: flex;
    flex-direction: column;
    // position: absolute;
    width: 351px;
    height: 591px;
    margin: 0 auto;

    // justify-content: center;
    align-items: center;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    overflow: hidden;
    .hero-image-container {
      width: 370px;
      margin-top: -3px;
    }
    .profile-image-container {
      // position: absolute;
      margin: 0 auto;
      margin-top: -80px;
      border: solid 16px #fff;
      display: flex;
      // width: 150px;
      // height: 150px;
      border-radius: 50%;
      .chakra-avatar {
        width: 150px;
        height: 150px;
        // width: 100%;
        // height: 100%;
      }
    }

    h3 {
      font-family: "Syne";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      /* Grey/Dark Grey */

      color: #111111;
      margin: 18px 0 8px 0;
    }
    p {
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      /* Grey/Dark Grey Faded */

      color: rgba(17, 17, 17, 0.5);
      margin: 5px 0 8px 0;
    }
    button {
      width: 253px;
      height: 37px;

      /* Blue Plimary Colors/Blue */

      background: #0000ff;
      border-radius: 30px;

      margin: 5px 0 8px 0;

      // text
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      /* White/White */

      color: #ffffff;
    }
    .more-info-container {
      // border: solid;
      // justify-content: center;
      margin-top: 30px;
      padding: 0 20px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-evenly;
      gap: 20px;
      & > div {
        // border: solid;
        // width: 40%;
        display: flex;
        align-items: center;
        gap: 10px;
        // text

        font-family: "Quicksand";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        /* Grey/Dark Grey */

        color: #111111;
        .icon-container {
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          /* White/Silver */

          background: #c0c0c0;
        }
      }
    }
  }

  .project-info-card {
    margin: 0 auto;
    width: 695px;
    // height: 1204px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 40px 24px;

    .top {
      h3 {
        /* Website Fonts/Headings/Third title */

        font-family: "Syne";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        /* identical to box height */

        /* Grey/Dark Grey */

        color: #111111;
      }
      p {
        font-family: "Quicksand";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        /* Grey/Dark Grey Faded */

        color: rgba(17, 17, 17, 0.5);
        padding: 10px 0;
      }
      button {
        width: 102px;
        height: 32px;

        /* Blue Plimary Colors/Blue */

        background: #0000ff;
        border-radius: 20px;

        // text
        font-family: "Quicksand";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #ffffff;
      }
      .icon-container {
        /* Blue Plimary Colors/Blue Faded */

        border: 1.5px solid rgba(0, 0, 255, 0.5);
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      .price {
        font-family: "Quicksand";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        /* Blue Plimary Colors/Blue */

        color: #0000ff;
      }
      .date {
        font-family: "Quicksand";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        /* Grey/Dark Grey Faded */

        color: rgba(17, 17, 17, 0.5);
      }
      .chakra-divider {
        width: 23px;
        height: 0px;

        /* Grey/Dark Grey Faded */

        border: 2px solid rgba(17, 17, 17, 0.5);
        transform: rotate(90deg);
      }
    }
    .info-grade {
      // border: solid;
      margin: 20px 0;
      .info-card {
        display: flex;
        align-items: center;
        gap: 20px;
        // border: solid;
        width: 30%;
        .icon {
          // border: solid 1px red;

          svg {
            /* Blue Plimary Colors/Blue Faded */

            color: rgba(0, 0, 255, 0.5) !important;
            // background-color: rgba(0, 0, 255, 0.5) !important;
            font-size: 25px;
          }
        }
        span {
          font-family: "Quicksand";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          /* identical to box height */

          // display: flex;
          // align-items: center;

          /* Grey/Dark Grey Faded */

          color: rgba(17, 17, 17, 0.5);
        }
        b {
          font-family: "Quicksand";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          /* identical to box height */

          // display: flex;
          // align-items: center;

          /* Grey/Dark Grey */

          color: #111111;
        }
        b.bg {
          // width: 118px;
          padding: 2px 8px;
          height: 25px;

          background: #d9d9d9;
          border-radius: 5px;
        }
      }
    }

    .project-info-card-body {
      b {
        font-family: "Syne";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #000000;
        margin: 25px 0;
      }
      p {
        font-family: "Quicksand";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: justify;

        /* Grey/Dark Grey Faded */

        color: rgba(17, 17, 17, 0.5);
      }
      .project-milestones-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .milestone {
          margin-top: 20px;
          .text {
            width: 259px;
            height: 28px;
            background: #d9d9d9;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            // padding: 5px 20px;
            padding-left: 8px;
            // text

            font-family: "Quicksand";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: justify;

            /* Grey/Dark Grey Faded */

            color: rgba(17, 17, 17, 0.5);
          }
        }
      }

      .gallery-container {
        .image-container {
          width: 149px;
          height: 132px;

          border-radius: 5px;
          margin: 10px 0;
        }
      }
    }
  }
}

.Loading_screen {
  width: 100%;
  font-size: 30px;
  text-align: center;
  color: #111111;
  margin-top: 30px;
}
