.ProfilePage {
  // min-height: 1700px;
  .top-container {
    margin-bottom: 20px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    b {
      /* Blue Plimary Colors/Blue */

      color: #0000ff;
    }
  }
  .icon_container {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #0000fa;
    font-size: 15px;
  }
  .profile-form-container {
    width: 100%;
    min-height: 848px;
    background: #ffffff;
    border-radius: 20px;
    padding: 28px 34px;

    .top-container {
      display: flex;
      justify-content: space-between;
      h2 {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        /* identical to box height */

        color: #000000;
      }

      .button-container {
        display: flex;
        gap: 10px;
        button {
          padding: 0px 15px;

          height: 32px;

          border-radius: 20px;

          // text
          font-family: 'Quicksand';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: rgba(17, 17, 17, 0.4);
        }
      }
    }

    form {
      .divider-container {
        // border: solid;
        display: flex;
        align-items: center;
        justify-content: space-between;
        b {
          font-family: 'Quicksand';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          /* identical to box height */

          color: #000000;
        }
        hr {
          width: 542px;
          height: 0px;

          border: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
      .css-0 {
        margin: 10px 0;
      }
    }
  }
  .h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    /* Grey/Dark Grey */

    color: #111111;
    margin: 20px 0;
  }

  .empty-project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 100%;

    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.25px;
    text-align: center;
    p {
      width: 446px;
      height: 36px;
      font-size: 14px;
      line-height: 18px;

      color: #111111;

      opacity: 0.7;

      margin-top: 20px;
    }

    .create-btn {
      margin-top: 20px;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */
      cursor: pointer;

      color: #0000fa;
    }
  }
  .profile-card {
    position: relative;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // width: 351px;
    // height: 591px;
    margin: 0 auto;
    padding-bottom: 10px;

    // justify-content: center;
    align-items: center;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
    .hero-image-container {
      margin-top: -3px;
    }
    .profile-image-container {
      // position: absolute;
      margin: 0 auto;
      // margin-top: -80px;
      border: solid 16px #fff;
      display: flex;
      // width: 150px;
      // height: 150px;
      border-radius: 25%;
      .chakra-avatar {
        width: 150px;
        height: 150px;
        // width: 100%;
        // height: 100%;
      }
    }

    h3 {
      font-family: 'Syne';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      /* Grey/Dark Grey */

      color: #111111;
      margin: 18px 0 8px 0;
    }
    p {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      letter-spacing: 0.25px;

      color: rgba(0, 0, 0, 0.6);
    }
    button {
      width: 253px;
      height: 37px;

      /* Blue Plimary Colors/Blue */

      background: #0000ff;
      border-radius: 30px;

      margin: 5px 0 8px 0;

      // text
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      /* White/White */

      color: #ffffff;
    }
    .more-info-container {
      // border: solid;
      // justify-content: center;
      width: 100%;
      margin-top: 0;
      padding: 0 20px;
      display: flex;
      // flex-wrap: wrap;
      flex-direction: column;
      // justify-content: space-evenly;
      // gap: 20px;
      & > div {
        // border: solid;
        // width: 40%;
        display: flex;
        align-items: center;
        gap: 10px;
        // text

        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        /* Grey/Dark Grey */

        color: #111111;
        .icon-container {
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          /* White/Silver */

          // background: #c0c0c0;
        }
      }
    }
    .settings {
      display: flex;
      justify-content: center;
      width: 322px;
      height: 199px;
      margin-bottom: 15px;
      background: rgba(238, 238, 238, 0.7);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;

      & > div {
        width: 100%;
        cursor: pointer;
        span {
          font-family: 'Quicksand';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          text-align: right;
          letter-spacing: 0.25px;

          /* Light/Primary */

          color: #1573fe;
          text-align: end;
          float: right;
        }
      }
    }
  }
}
.portfolio-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .PortfolioCard {
    width: 221px;
    height: 187px;

    cursor: pointer;
    .image-container {
      position: relative;
      img {
        width: 100%;
        height: 180px;
        object-fit: cover;
      }
      button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        top: 45%;
        background-color: #111111;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        font-size: 10px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      }
      .left {
        left: 5px;
      }
      .right {
        right: 5px;
      }
      button:hover {
        background-color: #fff;
        color: #000000;
      }
      // button[disabled]{
      //   background-color: red;
      // }
    }
    h3 {
      margin-top: 10px;
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      letter-spacing: 0.25px;

      color: #0000fa;
    }
  }
}
